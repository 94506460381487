import {useState, useEffect} from 'react'
import { Box, Divider, Typography, Stack, MenuItem, Grid, IconButton } from '@mui/material';
import axios from 'axios';
import * as React from 'react';
import Popover from '@mui/material/Popover';
import Iconify from 'components/iconify';
import { alpha, useTheme } from '@mui/material/styles';




function CurrencyRate() {
  const theme1 = useTheme();
  const [currencyData, setData] = useState(null)
    const [firstRow, setFirstRow] = useState(null);
    const url = '//monxansh.appspot.com/xansh.json?currency=USD|EUR|JPY|GBP|RUB|CNY|KRW';

    const [anchorEl, setAnchorEl] = useState(null);

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;
    
  const fetchJson = async () => {
      await axios
          .get(url)
          .then((res) => {
            setData(res.data) 
            setFirstRow(res.data[0]);
    });
  }
  
  useEffect(() => {
    fetchJson()
    
  },[])


  return (

  <Grid container
    sx={{ display: 'flex', alignItems: 'left', cursor: 'pointer', marginRight:1, fontSize:14, color: 'black', border:0, width:220 }}
  >
<Typography
  aria-owns={open ? 'mouse-over-popover' : undefined}
  aria-haspopup="true"
  onMouseEnter={handlePopoverOpen} 
  sx={{fontSize:12,textAlign:'center'}} >

   <Iconify icon="ic:baseline-currency-exchange" sx={{width:350, border:0, color:  '#FF7F00'}}/>  Ханш   
   {/* theme1.palette.primary  */}
</Typography>
<Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClick={handlePopoverClose}
        onClose={handlePopoverClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
  <Grid sx={{ p: 1, height:240 }}>
    <iframe title="hansh1" style={{height:220, border: 0}}
        src="//monxansh.appspot.com/xansh.html?currency=USD|EUR|JPY|GBP|RUB|CNY|KRW">hansh</iframe>
  </Grid>
</Popover>
</Grid>
  )
}

export default CurrencyRate